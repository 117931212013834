import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import { PATH_AFTER_LOGIN } from '../config';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import DashboardLayout from '../layouts/dashboard';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={true} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([

    {
      path: '/',
      element: (<GuestGuard><Login /></GuestGuard>),
    },

    {
      path: '/dashboard',
      element: (<AuthGuard><DashboardLayout /></AuthGuard>),
      children: [
        { path: '', element: <Dashboard /> },
      ],
    },
    {
      path: '/profile',
      element: (<AuthGuard><DashboardLayout /></AuthGuard>),
      children: [
        { path: '', element: <Profile /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'restaurant',
      element: (<AuthGuard><DashboardLayout /></AuthGuard>),
      children: [
        { path: 'list', element: <RestaurantList /> },
      ],
    },
    {
      path: 'room',
      element: (<AuthGuard><DashboardLayout /></AuthGuard>),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'list', element: <RoomList /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      // element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: 'restaurant-feedback/:customerID', element: <RestaurantFeedbackForm /> },
        { path: 'room-feedback/:customerID', element: <RoomFeedbackForm /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));

// DASHBOARD
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const Profile = Loadable(lazy(() => import('../pages/user/UserAccount')));


// Restaurant
const RestaurantList = Loadable(lazy(() => import('../pages/restaurant/Restaurant')));
const RoomList = Loadable(lazy(() => import('../pages/room/Room')));

// MAIN
const RestaurantFeedbackForm = Loadable(lazy(() => import('../pages/feedback/RestaurantFeedbackForm')));
const RoomFeedbackForm = Loadable(lazy(() => import('../pages/feedback/RoomFeedbackForm')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
