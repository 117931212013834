// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import useSettings from 'src/hooks/useSettings';

// ----------------------------------------------------------------------

export default function RestaurantIllustration({ ...other }) {
  const theme = useTheme();
  const { themeMode } = useSettings()
  const PRIMARY_LIGHTER = theme.palette.primary.lighter;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <Box {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 45 45" >
        <path
          fill={themeMode == 'dark' ? PRIMARY_LIGHTER : PRIMARY_MAIN}
          d="M32.238,24.393c0.005-0.014,3.723-8.698,3.529-8.799c-0.188-0.099-2.591,4.812-4.342,8.137l-0.55-0.289 c1.526-3.443,3.896-8.371,3.702-8.47c-0.188-0.099-2.744,4.729-4.604,7.999l-0.55-0.288c1.632-3.39,4.153-8.236,3.964-8.335c-0.19-0.099-2.904,4.647-4.871,7.86l-0.553-0.291c1.742-3.331,4.417-8.096,4.229-8.195c-0.191-0.101-4.152,6.226-5.23,7.904c-0.939,1.467-0.876,3.505,0.325,4.599c-1.644,2.875-3.285,5.747-4.928,8.621c-1.686-2.871-3.372-5.744-5.059-8.615c1.963-1.52,2.291-4.537,0.681-6.894c-1.689-2.519-5.22-5.316-7.323-4.26c-2.037,1.179-1.638,5.666-0.457,8.46c1.087,2.638,3.792,4.021,6.137,3.213c1.647,3.33,3.293,6.659,4.938,9.988c-0.72,1.261-1.44,2.521-2.161,3.783c-8.514-1.592-14.961-9.047-14.961-18.021C4.156,12.368,12.368,4.155,22.5,4.155s18.344,8.212,18.344,18.344c0,9.034-6.535,16.524-15.134,18.046c-0.748-1.273-1.495-2.545-2.241-3.818c1.6-3.33,3.197-6.66,4.797-9.992c1.564,0.395,3.211-0.582,3.967-2.346C33.025,22.543,32.236,24.393,32.238,24.393z M24.189,42.621c-0.561,0.047-1.119,0.086-1.689,0.086c-0.626,0-1.244-0.038-1.856-0.094c-0.349,0.726-0.698,1.451-1.045,2.178C20.551,44.915,21.516,45,22.5,45c0.939,0,1.861-0.077,2.771-0.189C24.911,44.082,24.55,43.352,24.189,42.621z M22.5,0C10.094,0,0,10.094,0,22.5c0,10.494,7.231,19.307,16.966,21.783c0.393-0.688,0.785-1.374,1.178-2.061C9.09,40.223,2.292,32.145,2.292,22.5c0-11.143,9.065-20.207,20.207-20.207c11.142,0,20.207,9.065,20.207,20.207c0,9.695-6.868,17.812-15.992,19.757c0.401,0.685,0.804,1.37,1.206,2.056C37.712,41.876,45,33.035,45,22.5C45,10.094,34.906,0,22.5,0z M23.29,40.805c-0.3-0.609-0.601-1.217-0.902-1.826c-0.29,0.606-0.581,1.211-0.871,1.815c0.328,0.017,0.652,0.049,0.983,0.049C22.767,40.844,23.025,40.814,23.29,40.805z" />
      </svg>
    </Box>
  );
}
