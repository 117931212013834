import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import KingBedIcon from '@mui/icons-material/KingBed';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import RestaurantTwoToneIcon from '@mui/icons-material/RestaurantTwoTone';
const navConfig = [
  {
    subheader: 'General',
    items: [
      {
        title: 'Dashboard',
        path: '/dashboard',
        icon: <DashboardOutlinedIcon />,
      },
      {
        title: 'Profile',
        path: '/profile',
        icon: <PersonOutlineOutlinedIcon />,
      },
    ],
  },
  {
    subheader: 'Review',
    items: [
      {
        title: 'Restaurant',
        path: '/restaurant/list',
        icon: <RestaurantTwoToneIcon />,
      },
      {
        title: 'Room',
        path: '/room/list',
        icon: <KingBedIcon />,
      },
    ],
  },




];

export default navConfig;
