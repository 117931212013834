// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function DeGlaceFavicon({ ...other }) {
    const theme = useTheme();
    const PRIMARY_MAIN = theme.palette.primary.main;
    const PRIMARY_DARK = theme.palette.primary.dark;
    const PRIMARY_DARKER = theme.palette.primary.darker;

    return (
        <Box {...other}>
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" version="1.1" viewBox="0 0 2161.78 3769.07" >
                <polygon fill={PRIMARY_MAIN} points="0.03,0 0.03,525.09 579.11,525.09 1375.94,525.09 2161.78,525.09 2161.78,0 " />
                <polygon fill={PRIMARY_MAIN} points="579.11,3243.98 2161.78,3243.98 2161.78,3769.07 0.03,3769.07 0.03,1626.9 579.11,1626.9 1375.94,1626.9 1965.51,1626.9 1965.51,2149.55 579.11,2149.55 " />
            </svg>
        </Box >
    );
}
