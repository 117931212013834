// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import useSettings from 'src/hooks/useSettings';

// ----------------------------------------------------------------------

export default function CheckOutIllustration({ ...other }) {
  const theme = useTheme();
  const { themeMode } = useSettings()
  const PRIMARY_LIGHTER = theme.palette.primary.lighter;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <Box {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 512 512" fill={themeMode == 'dark' ? PRIMARY_LIGHTER : PRIMARY_MAIN}>
        <rect x="112.351" y="167.38" width="126.976" height="80.44" />

        <rect x="272.673" y="167.38" width="126.976" height="80.44" />

        <polygon points="433.043,281.208 272.719,281.208 239.281,281.208 78.957,281.208 0,281.208 0,387.512 77.128,387.512 
			77.128,420.302 110.52,420.302 110.52,387.512 401.48,387.512 401.48,420.302 434.872,420.302 434.872,387.512 512,387.512 
			512,281.208 		"/>

        <polygon points="31.165,91.698 31.165,247.817 78.957,247.817 78.957,133.99 433.043,133.99 433.043,247.817 480.835,247.817 
			480.835,91.698 		"/>


      </svg>
    </Box>
  );
}
