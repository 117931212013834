import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';

import { DeGlance, } from "../../assets"

import Favicon from "../../assets/image/favicon.png"
import TopBanner from "../../assets/image/deglance-reception.jpg"

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		display: 'flex',
	},
}));

const HeaderStyle = styled('header')(({ theme }) => ({
	top: 0,
	zIndex: 9,
	lineHeight: 0,
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	position: 'absolute',
	padding: theme.spacing(3),
	justifyContent: 'center',
	[theme.breakpoints.up('md')]: {
		alignItems: 'flex-start',
		padding: theme.spacing(7, 5, 0, 7),
	},
}));

const SectionStyle = styled(Card)(({ theme }) => ({
	width: '100%',
	maxWidth: '65%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	margin: 0,
	borderRadius: 0
}));

const ContentStyle = styled('div')(({ theme }) => ({
	width: '30%',
	margin: 'auto',
	display: 'flex',
	minHeight: '100vh',
	flexDirection: 'column',
	justifyContent: 'center',
	padding: theme.spacing(12, 0),
	[theme.breakpoints.down('md')]: {
		width: "90%"
	},
}));

// ----------------------------------------------------------------------

export default function Login() {


	const smUp = useResponsive('up', 'sm');

	const mdUp = useResponsive('up', 'md');

	return (
		<Page title="Login">
			<RootStyle>
				{smUp &&
					<SectionStyle sx={{ textAlign: 'center' }}>
						<Image
							disabledEffect
							src={TopBanner}
							sx={{ width: '100%', margin: 'auto', height: "100vh" }}
						/>
					</SectionStyle>}

				<ContentStyle>

					<Stack direction="row" alignItems="center" sx={{ mb: 5 }}>

						<Box sx={{ flexGrow: 1, textAlign: "center" }}>
							<DeGlance sx={{ mb: 3.5 }} />

							<Typography variant="h4" gutterBottom>
								Sign in to De Glance
							</Typography>
							<Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
						</Box>
					</Stack>
					<LoginForm />
				</ContentStyle>
			</RootStyle>
		</Page>
	);
}
