// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function DeGlance({ ...other }) {
    const theme = useTheme();
    const PRIMARY_MAIN = theme.palette.primary.main;
    const PRIMARY_DARK = theme.palette.primary.dark;
    const PRIMARY_DARKER = theme.palette.primary.darker;

    return (
        <Box {...other}>
            <svg xmlns="http://www.w3.org/2000/svg" width="180" height="24" viewBox="0 0 209 24" fill="none">
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M39.2115 11.9746C39.2115 18.7325 33.9204 24 27.0959 24C20.2882 24 14.9803 18.7325 14.9803 11.9915C14.9803 5.25053 20.3051 0 27.1127 0C31.4097 0 35.1337 2.11715 37.2737 5.36909L34.1395 7.11362C32.6398 4.89485 30.0616 3.42131 27.0959 3.42131C22.344 3.42131 18.5694 7.16443 18.5694 12.0593C18.5694 16.8017 22.1586 20.5787 27.1296 20.5787C31.3254 20.5787 34.527 17.9365 35.4033 14.1426H29.1348V11.0261H39.1778C39.1947 11.2971 39.2115 11.6358 39.2115 11.9746ZM3.13423 0.305376H0V10.0612H3.13423C6.2853 10.0612 8.03777 8.1473 8.03777 5.18329C8.03777 2.23622 6.2853 0.305376 3.13423 0.305376ZM6.50436 5.20023C6.50436 7.45287 5.37537 8.72316 3.08367 8.72316H1.48286V1.67729H3.08367C5.37537 1.67729 6.50436 2.94758 6.50436 5.20023ZM6.75712 14.8032H3.55549H1.49971V17.6656H3.55549H6.2516V19.0206H1.49971V21.8491H6.75712V23.221H0V13.4482H6.75712V14.8032ZM67.6555 23.6952V20.4433H59.3986V0.304932H55.8263V23.6952H67.6555ZM92.9315 4.14967L87.4213 18.6479L85.5003 23.7121H81.7932L91.0105 0.304932H94.8525L104.087 23.6952H100.396L92.9315 4.14967ZM141.293 23.6952V0.304932H137.805V18.072L123.212 0.304932H120.213V23.6952H123.684V5.8942L138.26 23.6952H141.293ZM158.194 12.0085C158.194 5.25053 163.502 0 170.327 0C173.562 0 176.848 1.40579 178.988 3.59068L176.444 6.02964C174.91 4.40367 172.635 3.42131 170.327 3.42131C165.541 3.42131 161.783 7.16443 161.783 11.9915C161.783 16.8186 165.541 20.5618 170.327 20.5618C172.652 20.5618 174.91 19.5794 176.444 17.9534L178.988 20.3924C176.831 22.5942 173.545 23.9831 170.327 23.9831C163.502 24 158.194 18.7664 158.194 12.0085ZM208.696 20.4433H198.922V13.6514H207.482V10.3995H203.843H198.922H195.35V23.6952H208.696V20.4433ZM195.35 3.55687V0.304932H208.696V3.55687H203.843H198.922H195.35Z" fill={PRIMARY_MAIN}></path></svg>
        </Box >
    );
}
