import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import useLocalStorage from '../hooks/useLocalStorage';
import { apiLogin } from '../api/Login';

// ----------------------------------------------------------------------

const initialState = { isAuthenticated: false, isInitialized: false, user: null, };

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state, isAuthenticated: true, user,
    };
  },
  LOGOUT: (state) => ({
    ...state, isAuthenticated: false, user: null,
  }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken && isValidToken(accessToken)) {
          const userData = JSON.parse(localStorage.getItem('user'));
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: userData,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (phone_number, password) => {
    try {
      const data = { phone_number: phone_number, password: password, }
      const response = await apiLogin(data);
      setSession(response.data.result?.login_token)
      dispatch({
        type: 'LOGIN',
        payload: {
          user: response.data.result,
        },
      });
      window.location.reload()
      return response
    } catch (error) {
      return error
    }

  };


  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
