import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const user = JSON.parse(localStorage.getItem('user'));

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    token: "iwPsAU7FsZwDdxfrUKwvBVh0Pucw7nK7",
    key: "F6sA5J10d93K08tzkDSpHaX17OEhE5KX",
    application_id: "1",
    user_id: user ? user.user_id && user.user_id : "",
    login_token: user ? user.login_token && user.login_token : ""
  }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
